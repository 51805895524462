







import { Component, Vue } from "vue-property-decorator";

import NavBar from "@/components/menu/NavBar.vue";

@Component({
  components: {
    NavBar,
  },
})
export default class Help extends Vue {
  links = {
    normal: "Normal",
    important: "Important Checks mode",
  };
}
