












import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NavBar extends Vue {
  @Prop(Object) links!: { [key: string]: string };
  @Prop({ type: String, default: "200px" }) minWidth!: string;

  scroll(index: number) {
    const elem = this.$refs.links as HTMLElement;

    // make current tab centered within screen
    elem.scroll({
      left: index * 200 - (elem.offsetWidth - 200) / 2,
      behavior: "smooth",
    });
  }
}
